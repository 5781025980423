export default [
  {
    header: 'Admin',
    action: 'audit',
    resource: 'all',
  },
  {
    title: 'Settings',
    route: 'setup-config',
    icon: 'SettingsIcon',
    action: 'delete',
    resource: 'branch',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'Account',
        route: 'user-list',
        action: 'read',
        resource: 'user',
      },
      {
        title: 'Staff',
        route: { name: 'staff-list' },
        action: 'read',
        resource: 'staff',
      },
      {
        title: 'Audit Log',
        route: { name: 'audit-log-list' },
        action: 'audit',
        resource: 'all',
      },
    ],
  },
  {
    title: 'Branch',
    route: 'branch-list',
    icon: 'FlagIcon',
    action: 'read',
    resource: 'branch',
  },
  {
    title: 'Stock',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Category',
        route: 'category-list',
        action: 'read',
        resource: 'category',
      },
      {
        title: 'Item',
        route: { name: 'item-list' },
        action: 'read',
        resource: 'item',
      },
      {
        title: 'Unit',
        route: { name: 'unit-list' },
        action: 'read',
        resource: 'unit',
      },
    ],
  },
  {
    title: 'Sale Region',
    route: 'sale-region-list',
    icon: 'TruckIcon',
    action: 'read',
    resource: 'sale_region',
  },
  {
    title: 'Tag',
    route: 'tag-list',
    icon: 'TagIcon',
    action: 'read',
    resource: 'reason',
  },
  {
    title: 'Reason',
    route: 'reason-list',
    icon: 'InfoIcon',
    action: 'read',
    resource: 'reason',
  },
  {
    title: 'Reports',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Sale Revenues',
        route: 'report-sale-revenue',
        action: 'read',
        resource: 'report_sale_revenue',
      },
      {
        title: 'Sold Items',
        route: { name: 'report-sale-item' },
        action: 'read',
        resource: 'report_sale_item',
      },
      {
        title: 'Inventory Items',
        route: { name: 'report-inventory-item' },
        action: 'read',
        resource: 'report_inventory_item',
      },
    ],
  },
  {
    header: 'Store',
    action: 'read',
    resource: 'inventory',
  },
  {
    title: 'Inventory',
    route: 'inventory-list',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'inventory',
  },
  {
    title: 'Receipt',
    route: 'receipt-list',
    icon: 'DownloadIcon',
    action: 'read',
    resource: 'receipt',
  },
  {
    title: 'Issue',
    route: 'issue-list',
    icon: 'UploadIcon',
    action: 'read',
    resource: 'issue',
  },
  {
    title: 'Adjustment',
    route: 'adjustment-list',
    icon: 'SlidersIcon',
    action: 'read',
    resource: 'adjustment',
  },
  {
    header: 'Sale',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Client',
    route: 'client-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Invoice',
    route: 'sale-list',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'sale',
  },
  {
    header: 'Manage',
    action: 'read',
    resource: 'cash_book',
  },
  {
    title: 'Ledger',
    route: 'ledger-list',
    icon: 'BookmarkIcon',
    action: 'read',
    resource: 'ledger',
  },
  {
    title: 'Cash Book',
    route: 'cashbook-list',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'cash_book',
  },
  {
    title: 'Supplier',
    route: 'supplier-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'supplier',
  },
  {
    title: 'Purchase',
    route: 'purchase-list',
    icon: 'ShoppingCartIcon',
    action: 'read',
    resource: 'purchase',
  },
]
